import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import coma from "../../images/fintech/coma.svg"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import arrowleft from "../../images/cyberSecurity/left.png"
import arrowright from "../../images/cyberSecurity/right.png"
import nymcard from "../../images/fintech/nymcard.svg"
import acorons from "../../images/fintech/acorons.svg"
import akru from "../../images/fintech/akru.svg"
import spider from "../../images/fintech/spider-af.svg"
import forti from "../../images/fintech/forti.svg"
import * as styles from "./Kind2.module.scss"

const card = [
  {
    name: `ساتوكو أوتسوكي`,
    founder: `ساتوكو أوتسوكي`,
    dec: `"  أدى تطوير تطبيق InvoZone إلى تحويل مشهد أعمالنا. لقد أدى
    تكاملهم السلس وتصميمهم الذي يركز على المستخدم إلى تحويل
    رؤيتنا إلى حقيقة. لقد كانت احترافيتهم والتزامهم جديرة
    بالثناء حقًا.`,
    img: nymcard,
  },
  {
    name: `ساتوكو أوتسوكي`,
    founder: `مؤسس.`,
    dec: `لقد كان اختيار InvoZone لتطبيق التكنولوجيا المالية الخاص بنا
    قرارًا أتى بثماره بشكل كبير. إن خبرتهم في هذا المجال
    والتزامهم بتقديم حلول استثنائية تتماشى تمامًا مع احتياجاتنا.
    لا يمكننا أن نكون أكثر سعادة بالنتيجة`,
    img: acorons,
  },
  {
    name: `فيجي راجاسوندرام`,
    founder: `مستشار التكنولوجيا، AkruNow
    `,
    dec: ` لقد كانت خبرة InvoZone محورية في نجاح مشروعنا. كان تفاني
    فريقهم وحلولهم المبتكرة واضحًا في كل جانب من جوانب تطبيق
    التكنولوجيا المالية الخاص بنا. كان العمل معهم تجرب`,
    img: akru,
  },
  {
    name: `Sساتوكو أوتسوكي`,
    founder: `ساتوكو أوتسوكي`,
    dec: ` أدى تطوير تطبيق InvoZone إلى تحويل مشهد أعمالنا. لقد أدى
    تكاملهم السلس وتصميمهم الذي يركز على المستخدم إلى تحويل
    رؤيتنا إلى حقيقة. لقد كانت احترافيتهم والتزامهم جديرة
    بالثناء حقًا.`,
    img: spider,
  },
  {
    name: `ساتوكو أوتسوكي`,
    founder: ` مؤسس`,
    dec: ` لقد كان اختيار InvoZone لتطبيق التكنولوجيا المالية الخاص بنا
    قرارًا أتى بثماره بشكل كبير. إن خبرتهم في هذا المجال
    والتزامهم بتقديم حلول استثنائية تتماشى تمامًا مع احتياجاتنا.
    لا يمكننا أن نكون أكثر سعادة بالنتيجة`,
    img: forti,
  },
]

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          src={arrowright}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          src={arrowleft}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}

const InvoZoneHelps = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 1 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    speed: 200,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.teamWork}>
          <Container>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <Row className={`gap-30 ${styles.cardWrapper}`}>
              <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
                {card?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div className={styles.card}>
                      <img
                        src={coma}
                        decoding="async"
                        loading="lazy"
                        className={styles.coma}
                        alt="icon"
                      />
                      <p>{e?.dec}</p>
                      <hr />
                      <div className={styles.reviwer}>
                        <div>
                          <h3>{e?.name}</h3>
                          <p className={styles.found}>{e?.founder}</p>
                        </div>
                        <img src={e?.img} className="person" alt="icon" />
                      </div>
                    </div>
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoZoneHelps
