// extracted by mini-css-extract-plugin
export var faqButton = "Faqs2-module--faqButton--85d35";
export var faqsNum = "Faqs2-module--faqsNum--fcacc";
export var faqsSub = "Faqs2-module--faqsSub--92128";
export var head = "Faqs2-module--head--98384";
export var heading = "Faqs2-module--heading--57bce";
export var quesColor = "Faqs2-module--quesColor--18522";
export var quesColorSelected = "Faqs2-module--quesColorSelected--f003b";
export var section = "Faqs2-module--section--9bcb3";
export var serial = "Faqs2-module--serial--07a3e";
export var serial2 = "Faqs2-module--serial2--50d75";
export var textColor = "Faqs2-module--textColor--71ff0";