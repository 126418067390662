import React from "react"
import Accelerate from "../../components/fintech-landing-ar/Accelerate"
import Banner from "../../components/fintech-landing-ar/Banner"
import Build from "../../components/fintech-landing-ar/Build"
import Discover from "../../components/fintech-landing-ar/Discover"
import Faqs2 from "../../components/fintech-landing-ar/Faqs2"
import Featured from "../../components/fintech-landing-ar/Featured"
import Featured2 from "../../components/fintech-landing-ar/Featured2"
import Kind2 from "../../components/fintech-landing-ar/Kind2"
import Kinds from "../../components/fintech-landing-ar/Kinds"
import Testimonials from "../../components/fintech-landing-ar/Testimonials"
import Testimonials2 from "../../components/fintech-landing-ar/Testimonials2"
import Lending from "../../images/fintech/Lending-LendTech.svg"
import Payments from "../../images/fintech/Payments-PayTech.svg"
import Regulatory from "../../images/fintech/Regulatory-RegTech.svg"
import blockchain from "../../images/fintech/blockchain-and-cryptocurrency.svg"
import insurance from "../../images/fintech/insurance-InsurTech.svg"
import mobile from "../../images/fintech/mobile-payments.svg"
import step01 from "../../images/fintech/process-step-1-dark.svg"
import step1 from "../../images/fintech/process-step-1-light.svg"
import step02 from "../../images/fintech/process-step-2-dark.svg"
import step2 from "../../images/fintech/process-step-2-light.svg"
import step03 from "../../images/fintech/process-step-3-dark.svg"
import step3 from "../../images/fintech/process-step-3-light.svg"
import robo from "../../images/fintech/robo-advising-and-stock-trading-apps.svg"
import seamless2 from "../../images/fintech/seamless-integration-comprehensive-solutions-dark.svg"
import seamless from "../../images/fintech/seamless-integration-comprehensive-solutions.svg"
import strategic2 from "../../images/fintech/strategic-collaboration-dark.svg"
import strategic from "../../images/fintech/strategic-collaboration-light.svg"
import tailored2 from "../../images/fintech/tailored-fintech-app-development-dark.svg"
import tailored from "../../images/fintech/tailored-fintech-app-development-light.svg"
import trading from "../../images/fintech/trading-tradetech.svg"
import Acorns from "../../images/fintech/Acorns.jpeg"
import Akru from "../../images/fintech/Akru.png"
import Forti from "../../images/fintech/Forti.png"
import Nymcard from "../../images/fintech/Nymcard.png"
import SpiderAF from "../../images/fintech/SpiderAF.png"
import MainLayout from "../../layouts/MainLayout"
import Toggle from "../../components/fintech-landing/toggle"
import VideoSection from "../../components/fintech-landing/VideoSection"
export const Head = () => (
  <>
    <title>Fintech Software Development Company in Saudi Arabia</title>
    <meta
      name="description"
      content="Explore innovative Fintech software development company in KSA designed to transform your financial experience. Our experts develop top-notch fintech apps, ensuring seamless transactions."
    />
    <meta name="robots" content="noindex, nofollow"></meta>
    <meta name="googlebot" content="noindex, nofollow"></meta>
  </>
)
const FintechKSA = ({ location }) => {
  //   console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1281) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1281)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const ar_ver = [
    {
      title:
        "هل تبحث عن حلاً مبتكر لحلول التكنولوجيا المالية في المملكة العربية السعودية؟",
      subTitle:
        "هل تبحث عن خدمات تطوير تطبيقات التكنولوجيا المالية لشركتك الناشئة، البنك، أو شركة الدفع في السعودية؟ انتهت بحثك هنا مع InvoZone.",
      buttons: [
        {
          title: "حدد موعدًا للفتحة الخاصة بك الآن",
          url: "contact-us",
        },
      ],
    },
    {
      title: "اكتشف خبرتنا في حلول برمجيات التكنولوجيا المالية",
      subTitle:
        "بفضل خبرتنا التي تزيد عن 15 عامًا، نحن رواد في مجال التكنولوجيا المالية. تتمثل مهارتنا في تقديم حلول مخصصة للشركات الناشئة في مجال التكنولوجيا المالية، والبنوك، وشركات الدفع في المملكة العربية السعودية.",
      subTitle2: "رؤيتك، خبرتنا",
      cards: [
        {
          title: "التعاون الاستراتيجي",
          subTitle:
            "فتح باب التحول المالي من خلال خبرتنا. نحن نتعامل مع تعقيدات التطوير، ونمكّنك من توجيه طاقتك نحو الابتكار ونمو الشركات الناشئة.",
          icon: strategic,
          icon2: strategic2,
        },
        {
          title: "دمج سلس، حلاول شاملةدمج سلس، حلاول شاملة",
          subTitle:
            "دمج واجهات الخلفية الحديثة مع واجهات أمامية سهلة الاستخدام بسلاسة. خدماتنا تعزز الابتكار، وتدفع بنمو الإيرادات، وتعزز رحلتك في عالم الشركات الناشئة.",
          icon: seamless,
          icon2: seamless2,
        },
        {
          title: "تطوير تطبيقات تكنولوجيا التمويل المخصصة",
          subTitle:
            "دعونا نساعد في تعزيز نمو البنوك والشركات الناشئة في المملكة العربية السعودية من خلال حلول تطوير تطبيقات مصممة للتحديات الحديثة، والتي تسهم في دفع الإيرادات والنجاح",
          icon: tailored,
          icon2: tailored2,
        },
      ],
    },
    {
      video_link: "",
    },
    {
      title: "اكتشف خبرتنا في حلول برمجيات التكنولوجيا المالية",
      subTitle:
        "بفضل خبرتنا التي تزيد عن 15 عامًا، نحن رواد في مجال التكنولوجيا المالية. تتمثل مهارتنا في تقديم حلول مخصصة للشركات الناشئة في مجال التكنولوجيا المالية، والبنوك، وشركات الدفع في المملكة العربية السعودية.",
      subTitle2: "تسريع رحلتك في التطوير",
      cards: [
        {
          title: "إطلاق فعّال",
          subTitle:
            "سرّع عملية تطوير منتجك مع InvoZone. نهجنا المرن يساهم في تسريع الحلول الرقمية، وتوفير الوقت والتكاليف. ركّز على تطوير وظائف النواة دون تعقيدات البناء من الصفر.",
          image: "",
        },
        {
          title: "حلول مخصصة",
          subTitle:
            "ابنِ مسارك الفريد مع نهجنا الم modifiعالي. قم بتخصيص مكونات مبنية مسبقًا لتوازي احتياجاتك، وأنشئ حلاً مميزًا يتفاعل مع رؤيتك",
          image: "",
        },
        {
          title: "تجارب المستخدم المحسنة",
          subTitle:
            "رفع مستوى رضا العملاء من خلال نهجنا الذي يركز على المستخدم. تعمل واجهاتنا وخدماتنا الشخصية وتفاعلاتنا السلسة على تعزيز ولاء العملاء ونمو الإيرادات.",
          image: "",
        },
        {
          title: "دعم كامل الطيف",
          subTitle:
            "من الفكرة إلى التنفيذ، InvoZone تقدم لكم الدعم. تطوير تطبيقات مخصصة، وتصميمات شخصية، وتدريب، ودعم شامل. التزامنا يضمن رحلة تطوير سلسة وناجحة.",
          image: "",
        },
      ],
    },
    {
      title: "نبني جميع أنواع تطبيقات التكنولوجيا المالية",
      subTitle2: "ماذا نقدم",
      cards: [
        {
          title: "الإقراض (تكنولوجيا الإقراض)",
          subTitle:
            "قم بثورة في تجارب الإقراض باستخدام خبرتنا في تطوير تطبيقاتنا. قم بإنشاء منصات تبسط عمليات الإقراض، مما يزيد من الوصول والكفاءة.",
          icon: Lending,
        },
        {
          title: "التنظيمي (تكنولوجيا التنظيم والمراقبة)",
          subTitle:
            "ابق مطابقًا للقوانين بسهولة من خلال تطوير تطبيقاتنا في مجال التكنولوجيا المالية. قم بتمكين عملك من حلول متقدمة تضمن الامتثال للمعايير التنظيمية المعقدة.",
          icon: Regulatory,
        },
        {
          title: "التأمين (تكنولوجيا التأمين)",
          subTitle:
            "رفع تجارب التأمين بواسطة تطوير تطبيقاتنا. قم بتبسيط العمليات، وتحسين تجارب العملاء، واعتنق التحول الرقمي داخل قطاع التأمين.",
          icon: insurance,
        },
        {
          title: "بلوكشين والعملات المشفرة",
          subTitle:
            "نحن نقدم خدمات تطوير تطبيقات التكنولوجيا المالية التي تلبي احتياجات بلوكشين والعملات المشفرة المعقدة. قم بإنشاء منصات آمنة ولامركزية تعيد تعريف المشهد المالي.",
          icon: blockchain,
        },
        {
          title: "تطبيقات الاستشارة الآلية وتداول الأسهم",
          subTitle:
            "دخول عالم الاستشارة الآلية وتداول الأسهم بثقة. حلول تطوير تطبيقاتنا تمهّد الطريق لتجارب استثمار شخصية وفعّالة.",
          icon: robo,
        },
        {
          title: "التداول (تكنولوجيا التداول)",
          subTitle:
            "مكّن عمليات التداول باستخدام خدمات تطوير تطبيقات التكنولوجيا المالية لدينا. قدم حلول مبتكرة تحسن تجارب التداول وعمليات اتخاذ القرار.",
          icon: trading,
        },
        {
          title: "المدفوعات عبر الهواتف المحمولة",
          subTitle:
            "عزز تجارب المدفوعات عبر الهواتف المحمولة باستخدام تطوير تطبيقاتنا في مجال التكنولوجيا المالية. قم بإنشاء واجهات سهلة الاستخدام تيسر عمليات التحويل السلسة والآمنة أثناء التنقل.",
          icon: mobile,
        },
        {
          title: "المدفوعات (تكنولوجيا المدفوعات)",
          subTitle:
            "قم بتحويل مشهد المدفوعات باستخدام تطوير تطبيقاتنا في مجال التكنولوجيا المالية. بناء حلول مدفوعات آمنة ومرنة تلبي احتياجات العملاء المتنوعة.",
          icon: Payments,
        },
      ],
      buttons: [
        {
          title: "تواصل معن",
          url: "contact-us",
        },
      ],
    },
    {
      title: "بعض الأعمال المميزة",
      subTitle2: "معرض الأعمال",
      cards: [
        {
          img: Nymcard,
          link: "https://nymcard.com/",
          title: `NymCard`,
          subTitle: `الوصول إلى أكثر من 150 واجهة برمجة تطبيقات (APIs) لبطاقات مسبقة الدفع، ومتعددة العملات، والشحن، والائتمان. قم بإطلاق حلاول مبتكرة بينما نتعامل مع التعقيدات.`,
        },
        {
          link: "https://www.acorns.com/",
          img: Acorns,
          title: `أكورونس`,
          subTitle: `استثمر الباقي بالتغيير، وقم بالتوفير والاستثمار معًا، واكسب استثمارات إضافية، وزيِّن معرفتك المالية `,
        },
        {
          link: "https://akrunow.com/",
          img: Akru,
          title: `AkruNow`,
          subTitle: `استثمر بسهولة، بدعم من فريق ذو خبرة ومرخصين في سوق الأوراق المالية. تحويل الاستثمار المعقد إلى بساطة.`,
        },
        {
          link: "https://spideraf.com/",
          img: SpiderAF,
          title: `Spider AF`,
          subTitle: `حسّن حملاتك بكفاءة وبناء قاعدة عملاء تولِّد إيرادات مع Spider AF، مقللة من الاحتيال الإعلاني.`,
        },
        {
          link: "https://forti.io/",
          img: Forti,
          title: `Forti.io`,
          subTitle: `انضم إلى المبدعين والأصدقاء أو الزملاء للعب والفوز بجوائز حصرية. رفع من مستوى التفاعل من خلال عروض ألعاب تفاعلية.`,
        },
      ],
    },
    {
      title: "ابدأ رحلتك في 3 خطوات بسيطة",
      subTitle2: "عمليتنا",
      cards: [
        {
          title: "شاهد التحول",
          subTitle:
            "استرخ وشاهد بينما يتشكل تطبيق التكنولوجيا المالية الخاص بك. سيقوم خبراؤنا بصياغة الحل الخاص بك بجدية، وتحويل المفهوم الخاص بك إلى واقع متطور.",
          icon: step3,
          icon2: step03,
        },
        {
          title: "شارك رؤيتك",
          subTitle:
            "شاركنا متطلبات مشروعك وأفكارك وأهدافك. سيقوم فريقنا بتحليل وتخصيص الحل الذي يتوافق تمامًا مع رؤيتك.",
          icon: step2,
          icon2: step02,
        },
        {
          title: " يتصل",
          subTitle:
            "ابدأ بالتواصل معنا. دعونا نناقش احتياجات تطوير تطبيقات التكنولوجيا المالية الخاصة بك ونستكشف كيف يمكننا تحقيق رؤيتك.",
          icon: step1,
          icon2: step01,
        },
      ],
      buttons: [
        {
          title: "تحويل رؤيتك إلى واقع ملموس",
          url: "contact-us",
        },
      ],
    },
    {
      title: "أسئلة مكررة",
      cards: [
        {
          title:
            "ما أنواع خدمات تطوير تطبيقات التكنولوجيا المالية التي تقدمونها؟",
          description:
            "تتضمن خدمات تطوير تطبيقات التكنولوجيا المالية لدينا مجموعة متنوعة من القطاعات، بما في ذلك بلوكشين، تكنولوجيا التأمين، تكنولوجيا الإقراض، تكنولوجيا التداول، والمزيد، مما يخدم مجموعة واسعة من احتياجات التكنولوجيا المالية.",
        },
        {
          title: " كيف تضمنون تلبية متطلبات التطبيق الخاصة بنا؟",
          description:
            "نبدأ كل مشروع بتحليل دقيق لاحتياجاتك الفريدة. من خلال التشاور والتعاون، نقوم بتخصيص حلولنا لتتوافق بشكل سلس مع رؤيتك وأهدافك.",
        },
        {
          title:
            " ما يميز نهج تطوير تطبيقات التكنولوجيا المالية الخاص بكم عن الباقين؟ ",
          description:
            "بفضل أكثر من 15 عامًا من الخبرة في الصناعة، يميز فريقنا معرفته العميقة، وقيمته المتمثلة في تحقيق رضا العميل، والالتزام الثابت بالابتكار، مما يضعنا في موقع الرواد في هذا المجال. ",
        },
        {
          title:
            " هل يمكنكم التعامل مع جوانب التنظيم المعقدة في قطاع التكنولوجيا المالية؟",
          description:
            "بالتأكيد. فريقنا الماهر يمتلك القدرة على التنقل في المشهد التنظيمي المعقد ضمن قطاع التكنولوجيا المالية. كن واثقًا أن تطبيقك سيتماشى مع جميع اللوائح المتعلقة.",
        },
        {
          title: "     ما هو مدى مدة العملية التنموية النموذجية؟",
          description:
            "مدة التنمية تختلف حسب تعقيد المشروع. ومع ذلك، تكنولوجيا الأجهزة البيولوجية المعاصرة تضمن تنمية ميسورة بينما نحافظ على أعلى معايير الجودة باستخدام منهجية سريعة الاستجابة.",
        },
      ],
    },
    {
      title: "ابدأ رحلتك في 3 خطوات بسيطة",
      subTitle2: "عمليتنا",
      cards: [
        {
          title: " يتصل",
          subTitle:
            "ابدأ بالتواصل معنا. دعونا نناقش احتياجات تطوير تطبيقات التكنولوجيا المالية الخاصة بك ونستكشف كيف يمكننا تحقيق رؤيتك.",
          icon: step1,
          icon2: step01,
        },
        {
          title: "شارك رؤيتك",
          subTitle:
            "شاركنا متطلبات مشروعك وأفكارك وأهدافك. سيقوم فريقنا بتحليل وتخصيص الحل الذي يتوافق تمامًا مع رؤيتك.",
          icon: step2,
          icon2: step02,
        },
        {
          title: "شاهد التحول",
          subTitle:
            "استرخ وشاهد بينما يتشكل تطبيق التكنولوجيا المالية الخاص بك. سيقوم خبراؤنا بصياغة الحل الخاص بك بجدية، وتحويل المفهوم الخاص بك إلى واقع متطور.",
          icon: step3,
          icon2: step03,
        },
      ],
      buttons: [
        {
          title: "تحويل رؤيتك إلى واقع ملموس",
          url: "contact-us",
        },
      ],
    },
  ]

  const heroSection = ar_ver[0]
  const discover = ar_ver[1]
  const video_link = ar_ver[2]
  const accelerate = ar_ver[3]
  const build = ar_ver[4]
  const kinds = ar_ver[5]
  const featured = ar_ver[5]
  const testimonials = ar_ver[6]
  const faqs = ar_ver[7]
  const testimonials2 = ar_ver[8]

  // const { metaTitle, metaDescription } = data?.strapiPage?.seo

  return (
    <MainLayout>
      <div style={{ fontFamily: "Janna LT" }}>
        <Toggle location={location} />
        <Banner strapiData={heroSection} />
        <Discover strapiData={discover} />
        <VideoSection />
        <Accelerate strapiData={accelerate} />
        <Build strapiData={build} />
        {!isMobile && !isTablet ? <Kinds /> : <Kind2 strapiData={build} />}
        {!isMobile ? (
          <Featured strapiData={featured} />
        ) : (
          <Featured2 strapiData={featured} />
        )}
        {!isMobile ? (
          <Testimonials strapiData={testimonials} />
        ) : (
          <Testimonials2 strapiData={testimonials2} />
        )}
        <Faqs2 strapiData={faqs} />
      </div>
    </MainLayout>
  )
}

export default FintechKSA
