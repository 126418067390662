import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import fintech from "../../images/fintech/desktop-hero-bg2.png"
import circle from "../../images/fintech/circle.svg"
import { openCalendlyPopup } from "../../utils"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <Row>
          <Col
            xl={{ span: 6, order: 1 }}
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
          >
            <div className={styles.bannerImg}>
              <img
                src={fintech}
                decoding="async"
                loading="lazy"
                alt="fintech"
              />
            </div>
          </Col>
          <Col
            xl={{ span: 6, order: 2 }}
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
          >
            <div className={styles.heading}>
              <img
                src={circle}
                className={styles.circle}
                decoding="async"
                loading="lazy"
                alt="fintech"
              />
              <h1 className={styles.bannerHeading}>
                هل تبحث عن حلاً مبتكر لحلول
                <br /> <span className={styles.fin}>التكنولوجيا المالية </span>
                في المملكة العربية السعودية؟
              </h1>
              <p>{strapiData?.subTitle}</p>
            </div>

            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <button
                  className={styles.btn_white2_border}
                  onClick={() => {
                    openCalendlyPopup()
                    return false
                  }}
                >
                  حدد موعدًا للفتحة الخاصة بك الآن
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
