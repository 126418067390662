// extracted by mini-css-extract-plugin
export var backDec = "Build-module--backDec--c945d";
export var bannerBtn = "Build-module--bannerBtn--070c6";
export var btn_white2_border = "Build-module--btn_white2_border--9680b";
export var cardWrapper = "Build-module--cardWrapper--bcd03";
export var flipbox = "Build-module--flipbox--0c075";
export var flipboxback = "Build-module--flipboxback--ad6f1";
export var flipboxfront = "Build-module--flipboxfront--4d91c";
export var flipboxinner = "Build-module--flipboxinner--bc7ef";
export var heading = "Build-module--heading--f8670";
export var servicesbox = "Build-module--servicesbox--e1a7e";
export var subservice = "Build-module--subservice--a757b";
export var teamBtn = "Build-module--teamBtn--c828b";
export var vision = "Build-module--vision--49a80";