import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Build.module.scss"
import { openCalendlyPopup } from "../../utils"

const Build = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <p className={styles.vision}>{strapiData?.subTitle2}</p>
        <h2 className={styles.heading}>{strapiData?.title}</h2>
        <Row className={`${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <div className={styles.servicesbox} key={i}>
              <div className={styles.flipbox}>
                <div className={styles.flipboxinner}>
                  <div className={styles.flipboxfront}>
                    <img
                      decoding="async"
                      loading="lazy"
                      alt="icon"
                      src={e?.icon}
                    />
                    <h3>{e?.title}</h3>
                  </div>
                  <div className={styles.flipboxback}>
                    <div
                      className={styles.backDec}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <button
              className={styles.btn_white2_border}
              onClick={() => {
                openCalendlyPopup()
                return false
              }}
            >
              تواصل معنا
            </button>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Build
