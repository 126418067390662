// extracted by mini-css-extract-plugin
export var SliderWidth = "Kind2-module--SliderWidth--b3e0a";
export var arrowImg = "Kind2-module--arrowImg--c58a7";
export var card = "Kind2-module--card--2f37a";
export var cardWrapper = "Kind2-module--cardWrapper--8021a";
export var cards = "Kind2-module--cards--05c05";
export var coma = "Kind2-module--coma--b3a61";
export var description = "Kind2-module--description--864d5";
export var fineBg = "Kind2-module--fineBg--9227f";
export var found = "Kind2-module--found--a67a1";
export var heading = "Kind2-module--heading--b4f14";
export var headingPremium = "Kind2-module--headingPremium--20fb6";
export var iconContainer = "Kind2-module--iconContainer--fcc7d";
export var iconContainerLeft = "Kind2-module--iconContainerLeft--a96eb";
export var portfolioArrowIcon = "Kind2-module--portfolioArrowIcon--d561c";
export var portfolioArrowIconCover = "Kind2-module--portfolioArrowIconCover--38248";
export var portfolioArrowRightIconCover = "Kind2-module--portfolioArrowRightIconCover--24cfd";
export var premiumImg = "Kind2-module--premiumImg--87c64";
export var providingImg = "Kind2-module--providingImg--bbc75";
export var reviwer = "Kind2-module--reviwer--d1ac4";
export var teamButton = "Kind2-module--teamButton--517ed";
export var teamWork = "Kind2-module--teamWork--b15cc";
export var trailBg = "Kind2-module--trailBg--ca065";