import React from "react"
import Container from "react-bootstrap/Container"
import acorons from "../../images/fintech/acorons.svg"
import akru from "../../images/fintech/akru.svg"
import coma from "../../images/fintech/coma.svg"
import forti from "../../images/fintech/forti.svg"
import nymcard from "../../images/fintech/nymcard.svg"
import spider from "../../images/fintech/spider-af.svg"
import * as styles from "./Kinds.module.scss"
import "./fintech.scss"

const InvoZoneHelps = () => {
  function setActive() {
    const educationCards = document.querySelectorAll(".education-card")
    educationCards.forEach(card => {
      card.classList.remove("active")
    })

    this.classList.add("active")
  }

  React.useEffect(() => {
    const educationCards = document.querySelectorAll(".education-card")
    educationCards.forEach(card => {
      card.addEventListener("click", setActive)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <Container>
          <p className={styles.vision}>شهادات العملاء</p>
          <h2 className={styles.heading}>
            ماذا يقول العميل عن الولايات المتحدة
          </h2>
          <div class="education" id="education">
            <div class="cards ms-5">
              <div id="one" class="education-card active">
                <div class="info">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    تجاوزت خبرة InvoZone التوقعات. لقد أحدثت حلولهم المصممة
                    خصيصًا تحولًا في خدماتنا ودفعتنا إلى طليعة الابتكار في مجال
                    التكنولوجيا المالية. إن تفانيهم واهتمامهم بالتفاصيل يميزهم
                    عن بعضهم البعض.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <div className="name">
                      <h3>عمر مرة</h3>
                      <p className={styles.found}>
                        الرئيس التنفيذي والمؤسس لشركة NymCard
                      </p>
                    </div>
                    <img src={nymcard} className="person" alt="icon" />
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    ارتفعت الكفاءة مع تطوير تطبيق InvoZone. لقد كان لتركيزهم على
                    المعاملات السلسة والخدمة المتميزة تأثير ملحوظ على الطريقة
                    التي نؤدي بها أعمالنا. إن قدرتهم على فهم احتياجاتنا الفريدة
                    أمر يستحق الثناء حقًا.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <div className="name">
                      <h3>نوح كيرنر</h3>
                      <p className={styles.found}></p>
                    </div>
                    <img src={acorons} className="person" alt="icon" />
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    لقد كانت خبرة InvoZone محورية في نجاح مشروعنا. كان تفاني
                    فريقهم وحلولهم المبتكرة واضحًا في كل جانب من جوانب تطبيق
                    التكنولوجيا المالية الخاص بنا. كان العمل معهم تجربة مجزية
                    حقًا.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <div className="name">
                      <h3>فيجي راجاسوندرام</h3>
                      <p className={styles.found}>
                        مستشار التكنولوجيا، AkruNow
                      </p>
                    </div>
                    <img src={akru} className="person" alt="icon" />
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    أدى تطوير تطبيق InvoZone إلى تحويل مشهد أعمالنا. لقد أدى
                    تكاملهم السلس وتصميمهم الذي يركز على المستخدم إلى تحويل
                    رؤيتنا إلى حقيقة. لقد كانت احترافيتهم والتزامهم جديرة
                    بالثناء حقًا.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <div className="name">
                      <h3>ساتوكو أوتسوكي</h3>
                      <p className={styles.found}>ساتوكو أوتسوكي</p>
                    </div>
                    <img src={spider} className="person" alt="icon" />
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    لقد كان اختيار InvoZone لتطبيق التكنولوجيا المالية الخاص بنا
                    قرارًا أتى بثماره بشكل كبير. إن خبرتهم في هذا المجال
                    والتزامهم بتقديم حلول استثنائية تتماشى تمامًا مع احتياجاتنا.
                    لا يمكننا أن نكون أكثر سعادة بالنتيجة
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <div className="name">
                      <h3>ساتوكو أوتسوكي</h3>
                      <p className={styles.found}>مؤسس</p>
                    </div>
                    <img src={forti} className="person" alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoZoneHelps
