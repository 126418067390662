import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Discover.module.scss"

const Discover = ({ strapiData }) => {
  return (
    <div className={`${styles.Discover}`}>
      <Container>
        <p className={styles.vision}>{strapiData?.subTitle2}</p>
        <h2 className={styles.heading}>{strapiData?.title}</h2>
        <p className={styles.description}>{strapiData?.subTitle}</p>
        <Row className={`gap-30 `}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} className="gap-30 d-flex" key={i}>
              <div className={styles.card}>
                <img
                  src={e?.icon}
                  alt="icon"
                  decoding="async"
                  className={styles.icon1}
                  loading="lazy"
                />
                <img
                  src={e?.icon2}
                  alt="icon"
                  decoding="async"
                  loading="lazy"
                  className={styles.icon2}
                />
                <h3>{e?.title}</h3>
                <p>{e?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Discover
