import React from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import arrow from "../../images/fintech/arrow.svg"
import "./fintech.scss"
import * as styles from "./Featured.module.scss"

const Featured = ({ strapiData }) => {
  return (
    <div className="p-85">
      <Container>
        <div className="featuredRight">
          <Tab.Container id="left-tabs-example" defaultActiveKey="NymCard">
            <Row className="gap-30">
              <Col lg={6} xs={12} className="pl-lg-0">
                <Tab.Content>
                  {strapiData?.cards?.map((e, i) => (
                    <Tab.Pane eventKey={`${e?.title}`} key={i}>
                      <div className={styles.boxImg}>
                        <div className={styles.boxContent}>
                          <div className={styles.link}>
                            <div>
                              <a href={e?.link}>
                                <img src={arrow} alt="icon" />
                              </a>
                            </div>
                            <h3>{e?.title}</h3>
                          </div>
                          <p>{e?.subTitle}</p>
                          <div className={`${styles.bannerBtn}`}>
                            <Link className={styles.btn_white2_border}>
                              التطبيق المصرفي
                            </Link>
                          </div>
                        </div>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.img}
                          className={styles.portImg}
                          alt="icon"
                        />
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col lg={6} xs={12} className="pr-lg-0">
                <p className={styles.vision}>{strapiData?.subTitle}</p>
                <h2 className={styles.heading}>{strapiData?.title}</h2>
                <Nav variant="pills" className={`flex-column navbarBlock`}>
                  {strapiData?.cards?.map((e, i) => (
                    <Nav.Item key={i}>
                      <Nav.Link eventKey={`${e?.title}`}>{e?.title}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Featured
