import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import minus from "../../images/Healthcare/minus.svg"
import plus from "../../images/Healthcare/plus.svg"
import * as styles from "./Faqs2.module.scss"
import "./faqs.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div type="button" className={styles.faqButton}>
          {isCurrentEventKey ? (
            <img src={minus} className={styles.quesColorSelected} alt="icon" />
          ) : (
            <img src={plus} className={styles.quesColor} alt="icon" />
          )}
        </div>
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Faqs = ({ strapiData }) => {
  return (
    <div className={styles.section}>
      <Container>
        <h2 className={styles.heading}>{strapiData?.title}</h2>
        <Row>
          <Col lg={12}>
            <div className="faqsAccordian">
              <Accordion defaultActiveKey={`ek-0`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card key={i} border="light" style={{ overflow: "visible" }}>
                    <ContextAwareToggle eventKey={`ek-${i}`}>
                      <div className={styles.faqsNum}>
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                        <span className={styles.serial}>{"0" + (i + 1)}</span>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={`ek-${i}`}>
                      <Card.Body className={styles.textColor}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.description,
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                    <hr style={{ margin: 0 }} />
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Faqs
